import React from 'react';
import { Spoiler, Table, List, ThemeIcon } from '@mantine/core';
import { CircleCheck, ReportMedical } from 'tabler-icons-react';
import Layout from '../../components/Layout';
import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';
import Tabs from '../../components/Tabs';
import team1 from '../../assets/images/team-1-1.jpg';
import adenuga from '../../assets/images/departments/nursing-services/adenuga.png';
import adeola from '../../assets/images/departments/nursing-services/adeola.png';
import adeoluwa from '../../assets/images/departments/nursing-services/adeoluwa.png';
import ajala from '../../assets/images/departments/nursing-services/ajala.jpeg';
import akinola from '../../assets/images/departments/nursing-services/akinola.png';
import alasi from '../../assets/images/departments/nursing-services/alasi.png';
import esther from '../../assets/images/departments/nursing-services/esther.png';
import oyegoke from '../../assets/images/departments/nursing-services/oyegoke.png';
import kayode from '../../assets/images/departments/nursing-services/kayode.jpg';

const NursingServices = () => {
  const units = [
    {
      name: 'Nursing Administration',
    },
    {
      name: 'General Nursing ',
    },
    {
      name: 'Midwives',
    },
    {
      name: 'Public Health Nurses',
    },
    {
      name: 'Psychiatric Nurses',
    },
    {
      name: 'Pre-Opeative Nurses',
    },
    {
      name: 'Orthopaedic Nurses',
    },
    {
      name: 'Nurses Anaesthetic',
    },
    {
      name: 'Accident & Emergency',
    },
    {
      name: 'Nurse Education',
    },
    {
      name: 'Midwives Education',
    },
    {
      name: 'Public Health Education',
    },
    {
      name: 'Ophthalmic Nurses',
    },
    {
      name: 'Paediatric Nurses',
    },
    {
      name: 'Critical Care Nurses',
    },
    {
      name: 'Burns and Plastic Nurses',
    },
    {
      name: 'Nephrology Nurses',
    },
    {
      name: 'Ear, Nose and Throat',
    },
    {
      name: 'Community Nurse',
    },
    {
      name: 'Oncology Nurses',
    },
    {
      name: 'Health Education',
    },
    {
      name: 'Social Work',
    },
    {
      name: 'Refraction Nurses',
    },
  ];

  return (
    <Layout pageTitle='Department of Nursing Services | LUTH'>
      <NavOne />
      <PageHeader title='Department of Nursing Services' />
      <section className='course-details'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='course-details__content'>
                <div className='course-details__top'>
                  <div className='course-details__top-left'>
                    <h2 className='course-details__title'>
                      Department of Nursing Services
                    </h2>
                  </div>
                </div>
                <Tabs>
                  <div label='Overview'>
                    <Spoiler
                      maxHeight={300}
                      hideLabel='Hide'
                      showLabel='Show More'
                    >
                      <p className='course-details__tab-text'>
                        The department of Nursing Services is a unique arm of
                        the Lagos University Teaching Hospital charged with the
                        major responsibility of nursing care to all our clients
                        while ensuring effective coordination of all activities
                        towards the delivery of quality care to patients.
                        <br />
                        <br /> The department is divided into two services for
                        effective and efficient coordination, <br />
                        <List
                          spacing='xs'
                          size='sm'
                          center
                          icon={
                            <ThemeIcon color='teal' size={24} radius='xl'>
                              <ReportMedical size={16} />
                            </ThemeIcon>
                          }
                        >
                          <List.Item>Hospital Services</List.Item>
                          <List.Item>Theatre Services</List.Item>
                        </List>
                        <br /> The Hospital Services include all the wards and
                        consultant outpatients including both Adult and Children
                        Emergency centres. DNS Oyegoke A. O is in charge of this
                        division.
                        <br />
                        <br /> The Theatre Services include all the operating
                        theatres and DNS Imafidon E. O is in charge.
                        <br />
                        <br />
                        The clinical Deputy Directors were given
                        responsibilities to oversee their allocated areas for
                        close monitoring to ensuring quality assurance.
                      </p>
                      <h2 className='blog-one__title' style={{ marginTop: 30 }}>
                        Vision
                      </h2>
                      <p className='course-details__tab-text'>
                        TO SET THE PACE OF EXCELLENCE IN IN CLINICAL NURSING
                        SERVICES THROUGH GLOBAL BEST PRACTICESS
                      </p>
                      <h2 className='blog-one__title' style={{ marginTop: 30 }}>
                        Mission
                      </h2>
                      <p className='course-details__tab-text'>
                        TO DELIVER PROMPT SERVICE WITH OPTIMAL CLIENTS,
                        SATISTACTION BY DEDICATED, COMPETENT AND HIGHLY
                        MOTIVIATED PROFESSIONALS IN COLLABORATION WITH THE OTHER
                        HEALTH CARE GIVERS.
                      </p>
                    </Spoiler>
                  </div>
                  <div label='Specialties'>
                    <p className='course-details__tab-text'>
                      <List
                        spacing='xs'
                        size='sm'
                        center
                        icon={
                          <ThemeIcon color='teal' size={24} radius='xl'>
                            <CircleCheck size={16} />
                          </ThemeIcon>
                        }
                      >
                        {units.map((data) => (
                          <List.Item key={data.name}>{data.name}</List.Item>
                        ))}
                      </List>
                    </p>
                  </div>
                  <div label='Staff'>
                    <React.Fragment>
                      <h2 className='blog-one__title'>
                        Nursing (Hospital Services)
                      </h2>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={oyegoke} alt='profile person' />
                        </div>
                        {/* <div className='blog-details__author-content'>
                          <h3>DNS. Oyegoke Adeduntan Olufunmilayo</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Head & Director, Nursing (Hospital Services)
                            <br />
                            <span className='detail-head'>
                              Qualifications:
                            </span>{' '}
                            BNSc., DIP. (NURSING ADMINISTRATION) PGD (HOSPITAL
                            MANAGEMENT) BPN,RM, RN.
                          </p>
                        </div> */}
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={ajala} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>DDNS. Ajala Cecilia Kehinde</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Acting Head Nursing Services Department
                            <br />
                            <span className='detail-head'>
                              Qualifications:
                            </span>{' '}
                            MSc., PH, BNSc., BSc.H/E, RN, RM & RA&EN.
                            <br />
                            <span className='detail-head'>
                              Areas of Coverage:
                            </span>{' '}
                            A, B, & D 'Block'
                            <br /> L/Ward
                            <br />
                            Dialysis Centre
                            <br />
                            Permanent Night Nurses
                            <br />
                            Clinical Teaching Unit
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={akinola} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>DDNS. Akinola Fasilat Olajumoke</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Deputy Director of Nursing Services
                            <br />
                            <span className='detail-head'>
                              Qualifications:
                            </span>{' '}
                            BNSc, RN, RM, DIP. Social Work
                            <br />
                            <span className='detail-head'>
                              Area of Coverage:
                            </span>{' '}
                            Consultant Outpatient Clinics (COPD) Adult Accident
                            and Emergency Centre Dermatology Clinic LUTH Annex,
                            Harvey Road, Yaba, Lagos.
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={adeola} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>DDNS. Hussain Adeola Taibat</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Deputy Director Nursing Services
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BNSc., RN, RM, RA&EN, FAMILY PLANNING.
                            <br />
                            <span className='detail-head'>
                              Area of Coverage:
                            </span>{' '}
                            E 'Block'
                            <br /> NNU
                            <br /> GEC
                            <br /> (Wards and Clinic)
                            <br /> CCU
                          </p>
                        </div>
                      </div>
                      <h2 className='blog-one__title' style={{ marginTop: 50 }}>
                        Nursing (Theatre Services)
                      </h2>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={esther} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>DNS. Imafidon Esther Omoyemi</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Head & Director, Nursing (Theatre Services)
                            <br />
                            <span className='detail-head'>
                              Qualifications:
                            </span>{' '}
                            MSc. (SOCIAL WORK), PGD. (SOCIAL WORK), BNSc., DIP.
                            (MANAGEMENT SCIENCE), DIP. PAEDIATRIC NURSING, RM,
                            RN.
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={alasi} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>DDNS. Alasi Iyabo Olanife</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Deputy Director Nursing (Theatre Services)
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BSNc., RPON, RM, RN
                            <br />
                            <span className='detail-head'>
                              Area of Coverage:
                            </span>{' '}
                            Modular Theatre
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={kayode} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>DDNS. Oluremi R. Ajoke Kayode</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Deputy Director Nursing (Theatre Services)
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BNSc., DIP. MANAGEMENT SCIENCE (ASCON), RPON, RM,
                            RN.
                            <br />
                            <span className='detail-head'>
                              Area of Coverage:
                            </span>{' '}
                            Paediatric Surgical Theatre (Kids Theatre)
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={adeoluwa} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>DDNS. Adeoluwa Adebunmi Adenubi</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Deputy Director Nursing (Theatre Services)
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BNSc., RPON, RM, RN.
                            <br />
                            <span className='detail-head'>
                              Area of Coverage:
                            </span>{' '}
                            Renal Theatre
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={adenuga} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>DDNS. Adenuga Oluwafunmilola Adekemi</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Deputy Director Nursing (Theatre Services)
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BNSc., RPON, RM, RN.
                            <br />
                            <span className='detail-head'>
                              Area of Coverage:
                            </span>{' '}
                            Accident and Emergency Theatre
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>DDNS. Olowu Oluyemisi Ganiyat</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Deputy Director Nursing (Theatre Services)
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MSc. In view, BNSc,. RPON, RM, RN
                            <br />
                            <span className='detail-head'>
                              Area of Coverage:
                            </span>{' '}
                            Labour Ward Theatre
                          </p>
                        </div>
                      </div>
                    </React.Fragment>
                  </div>
                  <div label='Contact'>
                    <React.Fragment>
                      <h2 className='blog-one__title'>Departmental Email</h2>
                      <p className='course-details__tab-text'>
                        Nursing Theatre Services:{' '}
                        <a href='mailto:nursingts@luth.gov.ng'>
                          nursingts@luth.gov.ng
                        </a>
                        <br />
                        Nursing Hospital Services:{' '}
                        <a href='mailto:nursinghs@luth.gov.ng'>
                          nursinghs@luth.gov.ng
                        </a>
                      </p>
                    </React.Fragment>
                  </div>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default NursingServices;
